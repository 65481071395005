@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body,
div {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Montserrat', sans-serif;
	letter-spacing: 3px;
}

p,
a,
h2,
h3,
h4,
li,
button,
input {
	font-family: 'Montserrat', sans-serif;
}

:root {
	font-size: 16px;
}

/*Utils*/
.d-none {
	display: none;
}

.d-flex {
	display: flex !important;
}

.text-center {
	text-align: center;
}

.container {
	padding: 35px 15px;
}

.container a {
	text-decoration: none;
	border: none;
	outline: none;
}

h2 {
	text-align: center;
	margin: 0;
	padding: 35px 0;
	font-size: 35px;
}

h4{
	font-size: 26px;
}

@media only screen and (min-width: 480px) {
	.container .carousel-civial {
		width: 95%;
    margin: 0 auto;
    height: 85vh;
	}
}

.galeria{
	padding-top: 0;
}

.pt-0{
	padding-top: 0;
}

.mt-5{
	margin-top: 5px;
}