.contact-main-container label{
  font-weight: 700;
  letter-spacing: 1px;
}

.contact-form-container{
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
}

.contact-form-inputs-containers{
  display: flex;
  flex-direction: column;
  margin: 30px;
  padding: 35px 0px;
}

.contact-form-inputs-containers input, .contact-form-inputs-containers label, .contact-form-inputs-containers textarea{
  margin-top: 10px;
  padding: 10px 0px;
  border-radius: 7px;
  outline: none;
  font-size: 22px;
}

.contact-form-inputs-containers input, .contact-form-inputs-containers textarea{
  border: 2px solid #000;
}

.__JnHV, ._3lwW_._lhmht{
  display: none;
}

@media only screen and (min-width: 480px){
  .contact-form-container{
    width: 55%;
  }
  .__JnHV{
    display: block;
  }
}