.hero-container{
  background-color: black;
  height: 91.5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 20%);
  background-attachment: fixed;
}


.hero-sub-text{
  color: white;
  font-size: 40px;
  margin-top: 0;
  text-align: center;
  padding: 10px 0;
}

.logo-hero{
  margin-top: 10%;
  width: 400px;
  height: 150px;
}

@media only screen and (min-width: 480px){
  .logo-hero{
    width: auto;
    height: auto;
  }

  .hero-sub-text{
    font-size: 40px;
    font-weight: lighter;
  }
  .hero-container{
    background: url(../images/roadmarking.jpg) center center/cover no-repeat;
  }

  .logo-hero{
    margin-top: 5%;
  }
}