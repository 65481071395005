.service-image{
  max-width: 100%;
  max-height: 128px;
  min-width: 230px;
}

.service-container{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  padding: 0px 0px 5px 0px;
  overflow: hidden;
  text-decoration: none;
  margin-bottom: 50px;
  background-color: #fff;
  border-radius: 5px;
}

.services-container{
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  justify-content: space-around;
}

@media only screen and (min-width: 480px){
  .service-container{
    max-width: 45%;
    margin: 2.5%;
  }

  .service-container.no-desc{
    margin: 1%;
    max-width: 23%;
  }
}


.services-container p, .services-container h4{
  margin: 0;
  margin-top: 10px;
  color: black;
}

.services-text-container{
  width: 100%;
  padding: 20px;
}

.gray-bg{
  background-color: #f5f2f2;
}

.service-container.no-desc a{
  display: flex;
  flex-wrap: wrap;
  
}

.service-container.no-desc .services-text-container h4{
  width: 100%;
}