footer{
  background-color: #242222;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px 15px !important;
}

.contact, .menu{
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.contact a , .menu a{
  color: #f6d036;
  text-decoration: none;
  font-size: 16px;
}

.contact h3, .menu h3{
  color: #f6d036;
  font-size: 25px;
  margin: 8px 0;
}
.contact a:hover, .menu a:hover{
  text-decoration: underline;
  transform: scale(1.1);
}

.logo-footer{
  width: 225px;
  height: 75px;
}

.info-container{
  display: flex;
  justify-content: space-around;
}

.copyright-container{
  display: flex;
  flex-direction: column;
}

.copyright-container p{
  font-size: 14px;
  color: #f6d036;
  margin: 0 0 10px 0;
}

.copyright-container a{
  margin: 0 auto;
}


@media only screen and (max-width: 480px){
  .contact{
    width: 95%;
  }
}