@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body,
div {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Montserrat', sans-serif;
	letter-spacing: 3px;
}

p,
a,
h2,
h3,
h4,
li,
button,
input {
	font-family: 'Montserrat', sans-serif;
}

:root {
	font-size: 16px;
}

/*Utils*/
.d-none {
	display: none;
}

.d-flex {
	display: -webkit-flex !important;
	display: flex !important;
}

.text-center {
	text-align: center;
}

.container {
	padding: 35px 15px;
}

.container a {
	text-decoration: none;
	border: none;
	outline: none;
}

h2 {
	text-align: center;
	margin: 0;
	padding: 35px 0;
	font-size: 35px;
}

h4{
	font-size: 26px;
}

@media only screen and (min-width: 480px) {
	.container .carousel-civial {
		width: 95%;
    margin: 0 auto;
    height: 85vh;
	}
}

.galeria{
	padding-top: 0;
}

.pt-0{
	padding-top: 0;
}

.mt-5{
	margin-top: 5px;
}
footer{
  background-color: #242222;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 0px 15px !important;
}

.contact, .menu{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 15px 0;
}

.contact a , .menu a{
  color: #f6d036;
  text-decoration: none;
  font-size: 16px;
}

.contact h3, .menu h3{
  color: #f6d036;
  font-size: 25px;
  margin: 8px 0;
}
.contact a:hover, .menu a:hover{
  text-decoration: underline;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.logo-footer{
  width: 225px;
  height: 75px;
}

.info-container{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.copyright-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.copyright-container p{
  font-size: 14px;
  color: #f6d036;
  margin: 0 0 10px 0;
}

.copyright-container a{
  margin: 0 auto;
}


@media only screen and (max-width: 480px){
  .contact{
    width: 95%;
  }
}
.navbar {
  background: #242222;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  height: 80px;
  max-width: 1500px;
  width: 100%;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}


.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 15px;
  list-style: none;
  text-align: center;
  -webkit-justify-content: end;
          justify-content: end;
  margin-right: 2rem;
  width: 100%;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  padding: 0.5rem 0;
  height: 100%;
  letter-spacing: 2px;
}

.nav-links:hover {
  border-bottom: 4px solid #f6d036;
  transition: all 0.2s ease-out;
  color: #f6d036;
}

.navbar-menu-icon{
  display: none;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-right: 20px;
  transition: all 0.5s ease-out;
}

.line{
  width:25px;
  height:3px;
  background: #f6d036;
  margin: 2px 0;
  transition: 0.4s;
}

.close-menu{
  color: #f6d036;
}

.logo{
  width: 200px;
  height: 75px;
}

.rotate{
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: all 0.5s ease-out;
}


@media screen and (max-width: 480px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0;
    padding: 0;
    height: 76vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #f6d036;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .nav-links {
    display: -webkit-flex;
    display: flex;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #f6d036;
    transition: all 0.3s ease-out;
  }

  .nav-links:hover {
    background: #f6d036;
    color: #242424;
    transition: 250ms;
  }

  .navbar-menu-icon{
    display: -webkit-flex;
    display: flex;
  }

  .navbar-logo{
    margin-left: 20px;
  }
}
.hero-container{
  background-color: black;
  height: 91.5vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 20%);
  background-attachment: fixed;
}


.hero-sub-text{
  color: white;
  font-size: 40px;
  margin-top: 0;
  text-align: center;
  padding: 10px 0;
}

.logo-hero{
  margin-top: 10%;
  width: 400px;
  height: 150px;
}

@media only screen and (min-width: 480px){
  .logo-hero{
    width: auto;
    height: auto;
  }

  .hero-sub-text{
    font-size: 40px;
    font-weight: lighter;
  }
  .hero-container{
    background: url(/static/media/roadmarking.da70080b.jpg) center center/cover no-repeat;
  }

  .logo-hero{
    margin-top: 5%;
  }
}
:root{
  --primary: #f6d036;
}

.btn{
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  text-decoration: none;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 3px;
  padding: 8px 20px;
  border-radius: 0.6em;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  margin: 30px auto;
}

.btn-primary{
  color: #000;
  border: 2px solid #f6d036;
  border: 2px solid var(--primary);
  background-color: #f6d036;
  background-color: var(--primary);
}

.btn-outline{
  color: #fff;
  border: 2px solid #f6d036;
  border: 2px solid var(--primary);
}

.btn-medium{
  padding: 8px 20px;
  font-size: 20px;
}

.btn-long{
  padding: 10px 75px;
  font-size: 24px;
}

.btn-large{
  padding: 20px 40px;
  font-size: 30px;
}

.btn:hover, .btn:focus {
  box-shadow: 0 0 40px 40px #f6d036 inset;
  color: black;
  outline: 0;
  transition: all 300ms ease-out;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.contact-main-container label{
  font-weight: 700;
  letter-spacing: 1px;
}

.contact-form-container{
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
}

.contact-form-inputs-containers{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 30px;
  padding: 35px 0px;
}

.contact-form-inputs-containers input, .contact-form-inputs-containers label, .contact-form-inputs-containers textarea{
  margin-top: 10px;
  padding: 10px 0px;
  border-radius: 7px;
  outline: none;
  font-size: 22px;
}

.contact-form-inputs-containers input, .contact-form-inputs-containers textarea{
  border: 2px solid #000;
}

.__JnHV, ._3lwW_._lhmht{
  display: none;
}

@media only screen and (min-width: 480px){
  .contact-form-container{
    width: 55%;
  }
  .__JnHV{
    display: block;
  }
}
.service-image{
  max-width: 100%;
  max-height: 128px;
  min-width: 230px;
}

.service-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  padding: 0px 0px 5px 0px;
  overflow: hidden;
  text-decoration: none;
  margin-bottom: 50px;
  background-color: #fff;
  border-radius: 5px;
}

.services-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

@media only screen and (min-width: 480px){
  .service-container{
    max-width: 45%;
    margin: 2.5%;
  }

  .service-container.no-desc{
    margin: 1%;
    max-width: 23%;
  }
}


.services-container p, .services-container h4{
  margin: 0;
  margin-top: 10px;
  color: black;
}

.services-text-container{
  width: 100%;
  padding: 20px;
}

.gray-bg{
  background-color: #f5f2f2;
}

.service-container.no-desc a{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  
}

.service-container.no-desc .services-text-container h4{
  width: 100%;
}
@media only screen and (min-width: 480px){
  .full-page-height{
    height: 91.5vh;
  }
}

.services-link{
  display: grid;
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
  margin: 0 auto;
  padding: 70px 0px;
}

.services-link button{
  margin: 0;
}
