:root{
  --primary: #f6d036;
}

.btn{
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  display: flex;
  align-self: center;
  text-decoration: none;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 3px;
  padding: 8px 20px;
  border-radius: 0.6em;
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  margin: 30px auto;
}

.btn-primary{
  color: #000;
  border: 2px solid var(--primary);
  background-color: var(--primary);
}

.btn-outline{
  color: #fff;
  border: 2px solid var(--primary);
}

.btn-medium{
  padding: 8px 20px;
  font-size: 20px;
}

.btn-long{
  padding: 10px 75px;
  font-size: 24px;
}

.btn-large{
  padding: 20px 40px;
  font-size: 30px;
}

.btn:hover, .btn:focus {
  box-shadow: 0 0 40px 40px #f6d036 inset;
  color: black;
  outline: 0;
  transition: all 300ms ease-out;
  transform: scale(1.1);
}