.navbar {
  background: #242222;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  width: 100%;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}


.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 15px;
  list-style: none;
  text-align: center;
  justify-content: end;
  margin-right: 2rem;
  width: 100%;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0;
  height: 100%;
  letter-spacing: 2px;
}

.nav-links:hover {
  border-bottom: 4px solid #f6d036;
  transition: all 0.2s ease-out;
  color: #f6d036;
}

.navbar-menu-icon{
  display: none;
  align-items: center;
  flex-direction: column;
  margin-right: 20px;
  transition: all 0.5s ease-out;
}

.line{
  width:25px;
  height:3px;
  background: #f6d036;
  margin: 2px 0;
  transition: 0.4s;
}

.close-menu{
  color: #f6d036;
}

.logo{
  width: 200px;
  height: 75px;
}

.rotate{
  transform: rotate(90deg);
  transition: all 0.5s ease-out;
}


@media screen and (max-width: 480px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    height: 76vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    justify-content: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #f6d036;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .nav-links {
    display: flex;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #f6d036;
    transition: all 0.3s ease-out;
  }

  .nav-links:hover {
    background: #f6d036;
    color: #242424;
    transition: 250ms;
  }

  .navbar-menu-icon{
    display: flex;
  }

  .navbar-logo{
    margin-left: 20px;
  }
}